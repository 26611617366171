.whyUs {
    margin-top: 5%;
    flex-direction: row;
    background-color: rgba(163, 163, 163, 0.6);
    border-radius: 30px;
}

.whyUsImg {
    width: 100%;
}

.whyUsImg img {
    width: 90%;
}

.whyUsDetails {
    width: 100%;
    border-left: 1px solid rgb(0, 0, 0);
    flex-direction: column;
}

.whyUsDetailsHeader {
    margin-left: 3%;
    color: red;
    font-family: 'Playfair Display', serif;
    font-style: italic;
}

.whyUsDetailsBody {
    margin: 3% 3% 3% 3%;
    font-family: 'Montserrat', sans-serif;
}

.whyUsDetailsBody ul {
    margin-left: 3%;
    margin-top: 3%;
}

.whyUsDetailsBody ul li {
    font-weight: bolder;
    margin-top: 1.5%;
}

@media only screen and (max-width: 768px) {
    .whyUs {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .whyUsImg {
        border: 0;
    }
    .whyUsImg img {
        width: 70%;
    }
    .whyUsDetailsHeader {
        margin-left: 0;
        text-align: center;
    }
    .whyUsDetailsBody {
        margin: 3% 3% 8% 8%;
        font-family: 'Montserrat', sans-serif;
    }
    .whyUsDetailsBody ul {
        margin-top: 3%;
    }
    .whyUsDetailsBody ul li {
        font-weight: bolder;
        margin-top: 1.5%;
    }
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}