@import url(https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles&family=Montserrat:wght@300&family=Oswald:wght@300&display=swap);
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.mainBody {
    padding-top: 4%;
}

@media only screen and (max-width:768px) {
    .mainBody {
        padding-top: 10%;
    }
}

::-webkit-scrollbar {
    width: 5px;
}

 ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

 ::-webkit-scrollbar-thumb {
    background: rgb(255, 0, 0);
    border-radius: 10px;
}

 ::-webkit-scrollbar-thumb:hover {
    background: #b30000;
}
.hamContainer,
#hamLabel {
    width: 100%;
    height: 100%;
    flex-direction: column;
}

input[type=checkbox] {
    display: none;
}

#hamLabel span:nth-child(1),
#hamLabel span:nth-child(2),
#hamLabel span:nth-child(3) {
    width: 70%;
    height: .2rem;
    background-color: black;
    margin: 2px 40px 2px 2px;
    border-radius: 20px;
    transition: 0.3s transform ease-in-out;
}

.hamOption {
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    position: absolute;
    color: white;
    background-color: rgba(0, 0, 0, 0.61);
    display: none;
    opacity: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.hamOption ul {
    list-style: none;
    flex-direction: column;
}

.hamOption li {
    margin: 10%;
}

#ham:checked~.hamOption {
    display: flex;
    opacity: 1;
    animation: fade 1s;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.navBtn {
    background-color: #eee;
    border: none;
    padding: 1rem;
    font-family: 'Quicksand', sans-serif;
    font-size: 1rem;
    width: 10em;
    border-radius: 1rem;
    color: rgb(255, 0, 0);
    box-shadow: 0 0.4rem rgb(165, 165, 165);
    cursor: pointer;
}

.navBtn:active {
    color: white;
    box-shadow: 0 0.2rem #dfd9d9;
    transform: translateY(0.2rem);
}

.navBtn:hover:not(:disabled) {
    background: rgb(255, 0, 0);
    color: white;
    text-shadow: 0 0.1rem #bcb4b4;
}

#ham:checked~#hamLabel span:nth-child(1) {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

#ham:checked~#hamLabel span:nth-child(2),
#ham:checked~#hamLabel span:nth-child(3) {
    width: 8%;
    position: absolute;
    background-color: rgb(255, 0, 0);
    z-index: 1;
}

#ham:checked~#hamLabel span:nth-child(2) {
    transform: rotateZ(45deg);
}

#ham:checked~#hamLabel span:nth-child(3) {
    transform: rotateZ(-45deg);
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.navContainer,
.navLogo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navContainer {
    width: 100%;
    position: fixed;
    top: 0;
    margin-bottom: 5%;
    background-color: white;
    border-radius: 0 0 20px 20px;
    box-shadow: 0 2px 10px 2px gray;
    z-index: 20;
}

.navLogo img {
    width: 45%;
}

.navTitles {
    text-decoration: none;
    color: rgb(0, 0, 0);
    width: 10%;
    font-family: 'Zen Kurenaido', sans-serif;
    font-size: 1.4rem;
    padding: 0.2%;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
}

.navTitles:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(255, 0, 0);
    box-shadow: 0 2px 10px 0px gray;
    text-shadow: 0 0.1rem #bcb4b4;
}

.activeNav {
    color: rgb(255, 255, 255);
    background-color: rgb(255, 0, 0);
    box-shadow: 0 2px 10px 0px gray;
    text-shadow: 0 0.1rem #bcb4b4;
}

.hamBurger {
    display: none;
}

@media only screen and (max-width: 768px) {
    .navLogo {
        justify-content: flex-start;
        margin-left: 10px;
    }
    .navLogo img {
        width: 35%;
        height: 0%;
        border-radius: 0 0 0 10px;
    }
    .navTitles {
        display: none;
    }
    .hamBurger {
        display: flex;
    }
}
.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactContainer {
    margin: 2% 5% 5% 5%;
    padding: 0.5% 1% 1% 1%;
    font-family: 'Oswald', sans-serif;
    font-size: 1.2rem;
    box-shadow: 0px 0px 10px 2px gray;
    border-radius: 20px;
    align-items: flex-start;
}

.contactHeader h3 {
    font-size: 2rem;
    text-align: center;
    border-bottom: 1px solid red;
}

.contactBody {
    font-size: 1.3rem;
    margin-top: 2%;
    flex-direction: row;
}

.contactAddress,
.contactForm {
    width: 100%;
    padding: 2%;
    border-radius: 20px;
    border: 1px solid red;
    flex-direction: column;
}

.contactAddress {
    padding: 4.7%;
}

.contactAddress:hover,
.contactForm:hover {
    box-shadow: 0px 0px 5px rgb(255, 0, 0);
}

.perMail img {
    margin-right: 2%;
    width: 6.5%;
}

.perAddress {
    width: 100%;
}

.perName,
.perNumber,
.perMail {
    width: 100%;
    margin-bottom: 5%;
}

.perName svg {
    margin-right: 2%;
}

.perNumber svg {
    margin-right: 1%;
}

.perAddress svg {
    margin-right: 1%;
    margin-bottom: 21.5%;
}

.perNumber a,
.perMail a,
.perAddress a {
    color: rgb(0, 0, 0);
    text-decoration: none;
}

.perNumber a:hover,
.perMail a:hover,
.perAddress a:hover {
    color: rgb(255, 0, 0);
    text-decoration: none;
}

.contactAddress,
.contactForm {
    margin: 5%;
}

@media only screen and (max-width:768px) {
    .contactContainer {
        margin-top: 7%;
    }
    .contactHeader h3 {
        font-size: 1.8rem;
    }
    .contactAddress,
    .contactForm {
        border-radius: 0px;
        border-top: 1px solid red;
        border-left: 0px;
        border-bottom: 1px solid red;
        border-right: 0px;
    }
    .contactAddress:hover,
    .contactForm:hover {
        box-shadow: 0px 0px 5px rgb(255, 255, 255);
    }
    .perMail img {
        margin-right: 2%;
        width: 8.5%;
    }
    .contactBody {
        font-size: 1rem;
        margin-top: 6%;
        flex-direction: column;
    }
}
.contactForm h3 {
    width: 26%;
    font-size: 1.5rem;
    text-align: center;
    border-bottom: 1px solid red;
}

.formBody {
    margin-top: 8%;
}

.formName,
.formNumber,
.formEmail,
.formSubject,
.formMessage {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /* margin-bottom: 5%; */
}

.formName input,
.formNumber input,
.formSubject input {
    color: red;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    font-size: 1.2rem;
    border: 1px solid red;
    border-radius: 8px;
    outline: none;
    text-align: center;
}

.formSubject input {
    width: 71%;
}

.formName input:focus,
.formNumber input:focus,
.formSubject input:focus,
.formEmail input:focus,
.formMessage textarea:focus {
    border: none;
    border-bottom: 2px solid rgb(0, 0, 0);
    border-radius: 0px;
}

.formEmail input {
    width: 73%;
    color: red;
    font-family: 'Oswald', sans-serif;
    font-size: 1.1rem;
    border: 1px solid red;
    border-radius: 8px;
    outline: none;
    text-align: center;
}

.formMessage textarea {
    font-size: 1rem;
    color: red;
    font-family: 'Oswald', sans-serif;
    border: none;
    border: 1px solid red;
    border-radius: 8px;
    outline: none;
    text-align: center;
}

.alert {
    margin-top: 1%;
    margin-bottom: 5%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: red;
    font-weight: bolder;
    font-size: 0.8rem;
}

.formName input:-ms-input-placeholder, .formNumber input:-ms-input-placeholder, .formEmail input:-ms-input-placeholder, .formSubject input:-ms-input-placeholder, .formMessage textarea:-ms-input-placeholder {
    text-transform: capitalize;
}

.formName input::placeholder,
.formNumber input::placeholder,
.formEmail input::placeholder,
.formSubject input::placeholder,
.formMessage textarea::placeholder {
    text-transform: capitalize;
}

@media only screen and (max-width:768px) {
    .contactForm h3 {
        width: 35%;
    }
    .formName input,
    .formNumber input,
    .formEmail input,
    .formSubject input {
        font-size: 1rem;
    }
}

.formSubmit {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 2% 0 2% 0;
}

.formSubmit button {
    padding: 2.5%;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 172, 0);
    font-family: 'Oswald', sans-serif;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
    text-shadow: 0 0.1rem #000000;
}
.footerContainer {
    width: 100%;
    margin-top: 3%;
    padding: 2% 2% 2% 5%;
    background-color: rgb(163, 163, 163);
    border-radius: 20px 20px 0 0;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
}

.footerContent {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid black;
}

.footerHeader {
    width: 50%;
}

.footerProducts a {
    text-decoration: none;
    color: black;
}

.footerHeader ul,
.footerProducts ul {
    margin-top: 1%;
    list-style: url(/static/media/pointer.73edd4dd.svg);
    text-decoration: none;
}

.footerHeader ul li,
.footerProducts ul li {
    margin: 0.8rem;
}

.footerHeader ul li a,
.footerProducts ul li a {
    text-decoration: none;
    font-size: 1.5rem;
    color: black;
}

.footerHeader ul li a:hover,
.footerProducts a:hover,
.footerProducts ul li a:hover {
    -webkit-text-decoration: underline red;
            text-decoration: underline red;
    transition: all 0.3s ease-in-out;
}

.footerFooter {
    width: 100%;
    margin-top: 3%;
    display: flex;
    flex-direction: row;
}

.footerCopyright {
    width: 100%;
}

.footerCopyright a {
    text-decoration: none;
    color: black;
}

.footerCopyright a:hover {
    -webkit-text-decoration: underline red;
            text-decoration: underline red;
    transition: all 0.3s ease-in-out;
}

.footerSocial {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerSocial a {
    margin-left: 1%;
}

@media only screen and (max-width:600px) {
    .footerContainer {
        margin-top: 5%;
        padding: 5%;
    }
    .footerHeader {
        width: 100%;
        font-size: 0.8rem;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .footerHeader ul {
        list-style: none;
    }
    .footerHeader ul li a {
        font-size: 1.2rem;
    }
    .footerProducts {
        display: none;
    }
    .footerFooter {
        flex-direction: column-reverse;
    }
    .footerCopyright {
        text-align: center;
    }
}
.prodBody {
    margin-top: 3%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.prodCat1,
.prodCat2 {
    width: 100%;
    height: 40vh;
    overflow: hidden;
    padding: 1%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.catImage1,
.catImage2 {
    position: absolute;
    bottom: 35%;
    left: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.catImage1 img,
.catImage2 img {
    width: 85%;
    border-radius: 50%;
    box-shadow: 0px 0px 8px 3px rgba(255, 0, 0, 0.603);
    background-color: white;
}

.catDetail {
    width: 100%;
    height: 160px;
    padding: 1%;
    background-color: rgba(163, 163, 163, 0.5);
    border-radius: 20px;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
}

.text {
    margin-left: 40%;
}

.catHead {
    font-family: 'Oswald', sans-serif;
}

.catBody {
    font-size: 1.5rem;
    font-family: "Zen Kurenaido", sans-serif;
}

.catLink {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.catLink a {
    font-size: 1.1rem;
    color: black;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    text-align: center;
    display: flex;
    align-items: center;
}

.catLink a:hover {
    color: red;
    -webkit-text-decoration: underline black;
            text-decoration: underline black;
}

@media only screen and (max-width:768px) {
    .prodBody {
        margin-top: 0%;
        flex-direction: column;
        overflow: hidden;
    }
    .prodCat1,
    .prodCat2 {
        width: auto;
        height: 100%;
        margin: 7% 0 0 0;
        padding: 3%;
        border-radius: 20px;
        background-color: rgba(163, 163, 163, 0.5);
    }
    .catImage1,
    .catImage2 {
        position: relative;
        bottom: 0;
        left: 0;
        display: flex;
    }
    .catImage1 img,
    .catImage2 img {
        width: 150px;
        border-radius: 0%;
        box-shadow: none;
        background-color: transparent;
    }
    .catDetail {
        width: 100%;
        padding: 2%;
        height: 200px;
        background-color: transparent;
    }
    .catHead {
        font-size: .8rem;
    }
    .catBody {
        font-size: 1.3rem;
    }
    .text {
        margin-left: 2%;
    }
}
.prodCont {
    margin: 1%;
    display: flex;
    flex-direction: column;
    position: relative;
    /* z-index: -10; */
}

@media only screen and (max-width:768px) {
    .prodCont {
        margin: 0% 5% 0% 5%;
        display: flex;
        flex-direction: column;
    }
}
.details {
    margin-top: 3%;
    padding: 2%;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 0px 5px gray;
    overflow: hidden;
}

.detailsImage {
    width: 30%;
    border-right: 1px solid gray;
}

.detailsImage img {
    width: 70%;
}

.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-button-next,
.swiper-button-prev {
    color: red !important;
}

.detailsDetail {
    width: 100%;
    margin-left: 2%;
}

.detailsSubHeader {
    position: relative;
    top: -10px;
    width: 14%;
    border-bottom: 1px solid red;
}

.detailsTable {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.detailsTable table {
    width: 100%;
    font-family: 'Baloo Bhaijaan 2', cursive;
    text-align: left;
}

.table1 {
    margin-right: 10%;
}

td,
th {
    padding: 0.5% 0 0.5% 0;
    /* border-bottom: 1px solid red; */
}

@media only screen and (max-width:768px) {
    .details {
        margin-top: 7%;
        flex-direction: column;
    }
    .detailsDetail {
        margin-top: 4%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .detailsImage {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid gray;
    }
    .detailsSubHeader {
        width: 34%;
        text-align: center;
    }
    .detailsTable {
        display: flex;
        flex-direction: column;
    }
    .detailsTable table {
        width: 100%;
        text-align: center;
    }
    td,
    th {
        padding: 3% 0 3% 0;
        border-bottom: none;
    }
}
.detailsCont {
    position: relative;
    padding: 2%;
    display: flex;
    flex-direction: column;
    font-family: 'Playfair Display', serif;
}

.detailsCont h1 {
    width: 24%;
    border-bottom: 1px solid red;
}

.detailsCont h3 {
    margin-top: 1%;
}

@media only screen and (max-width:768px) {
    .detailsCont {
        margin: 5%;
        font-size: 0.8rem;
    }
    .detailsCont h1 {
        width: 63%;
        border-bottom: 1px solid red;
    }
}
.homeContainer {
    margin-bottom: 5%;
}

.homeSwiper img {
    width: 50%;
}

@media only screen and (max-width: 768px) {
    .homeContainer {
        margin-bottom: 10%;
    }
    .homeSwiper img {
        width: 100%;
    }
}
.homeDetails {
    margin-top: 3%;
    flex-direction: column;
    background-color: rgba(163, 163, 163, 0.6);
    border-radius: 30px;
}

.homeDetailsHeader {
    margin-top: 3%;
    font-family: 'Playfair Display', serif;
    font-style: italic;
}

.homeDetailsBody {
    flex-direction: row;
}

.technical,
.cost,
.support {
    width: 33.33%;
    margin: 3% 0 2% 0;
    flex-direction: column;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: large;
}

.technical,
.cost {
    border-right: 3px solid rgb(0, 0, 0);
}

.technicalLogo,
.costLogo,
.supportLogo {
    padding: 1.5%;
    border: 2px solid rgb(0, 0, 0);
    border-radius: 50%;
}

.technicalDetails,
.costDetails,
.supportDetails {
    color: red;
    font-weight: bolder;
}

.technicalDetails {
    margin: 3% 10% 2% 8%;
}

.costDetails {
    margin: 3% 5% 2% 5%;
}

.supportDetails {
    margin: 3% 2% 2% 4%;
}

@media only screen and (max-width:768px) {
    .homeDetailsHeader {
        margin-top: 5%;
        color: red;
    }
    .homeDetailsBody {
        flex-direction: column;
    }
    .technical,
    .cost,
    .support {
        width: auto;
    }
    .technical {
        margin-top: 10%;
    }
    .support {
        margin-bottom: 5%;
    }
    .technical,
    .cost {
        border-right: 0;
    }
    .technicalLogo,
    .costLogo,
    .supportLogo {
        border: 2px solid rgb(255, 0, 0);
    }
    .technicalDetails,
    .costDetails,
    .supportDetails {
        color: rgb(0, 0, 0);
        margin: 5% 5% 5% 5%;
    }
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.whyUs {
    margin-top: 5%;
    flex-direction: row;
    background-color: rgba(163, 163, 163, 0.6);
    border-radius: 30px;
}

.whyUsImg {
    width: 100%;
}

.whyUsImg img {
    width: 90%;
}

.whyUsDetails {
    width: 100%;
    border-left: 1px solid rgb(0, 0, 0);
    flex-direction: column;
}

.whyUsDetailsHeader {
    margin-left: 3%;
    color: red;
    font-family: 'Playfair Display', serif;
    font-style: italic;
}

.whyUsDetailsBody {
    margin: 3% 3% 3% 3%;
    font-family: 'Montserrat', sans-serif;
}

.whyUsDetailsBody ul {
    margin-left: 3%;
    margin-top: 3%;
}

.whyUsDetailsBody ul li {
    font-weight: bolder;
    margin-top: 1.5%;
}

@media only screen and (max-width: 768px) {
    .whyUs {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .whyUsImg {
        border: 0;
    }
    .whyUsImg img {
        width: 70%;
    }
    .whyUsDetailsHeader {
        margin-left: 0;
        text-align: center;
    }
    .whyUsDetailsBody {
        margin: 3% 3% 8% 8%;
        font-family: 'Montserrat', sans-serif;
    }
    .whyUsDetailsBody ul {
        margin-top: 3%;
    }
    .whyUsDetailsBody ul li {
        font-weight: bolder;
        margin-top: 1.5%;
    }
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.cardCont {
    width: 420px;
    padding: 10%;
    margin-bottom: 15%;
    background-color: white;
    border-radius: 20px;
    text-align: center;
    flex-direction: column;
}

.swiper-slide {
    margin-top: 2%;
}

.cardCont:hover {
    transform: scale(1.03);
    transition: 0.2s ease-out;
    cursor: pointer;
}

.cardLogo {
    margin-bottom: 5%;
}

.cardDetails {
    font-family: 'Montserrat', sans-serif;
}

@media only screen and (max-width:768px) {
    .cardCont {
        width: 320px;
    }
    .cardDetails {
        font-size: 0.7rem;
    }
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.whoAreWe {
    margin-top: 5%;
    flex-direction: row;
    background-color: rgba(163, 163, 163, 0.6);
    border-radius: 30px;
    flex-direction: column;
    overflow: hidden;
}

.whoAreWeHeader {
    margin: 2% 0 1% 0;
    font-family: 'Playfair Display', serif;
    font-style: italic;
}

.whoAreWeBody {
    width: 100%;
    flex-direction: row;
    grid-gap: 40px;
}

.swiper-pagination-bullet-active {
    background: red !important;
}

@media only screen and (max-width:768px) {
    .whoAreWeBody {
        margin-top: 10%;
        grid-gap: 0px;
        flex-direction: column;
    }
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.abtCont {
    overflow: hidden;
}

.abtHeader h1 {
    margin-top: 30px;
    color: red;
    font-size: 3rem;
    font-family: 'Oswald', sans-serif;
    text-align: center;
}

.abt1,
.abt2,
.abt3,
.abt4 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: stretch;
}

.abtImg {
    width: 40%;
}

.abtImg img {
    width: 50%;
}

.abtContent {
    width: 50%;
    font-family: "Zen Kurenaido", sans-serif;
}

.abtContent h1 {
    font-size: 3rem;
}

.abtContent p {
    font-size: 1.4rem;
}

@media only screen and (max-width:768px) {
    .abt1,
    .abt2,
    .abt3,
    .abt4 {
        padding: 5%;
        flex-direction: column;
    }
    .abtImg img {
        width: 300px;
        height: 200px;
    }
    .abtContent {
        width: 100%;
    }
    .abtContent h1 {
        font-size: 1.9rem;
        text-align: center;
    }
    .abtContent p {
        font-size: 1rem;
        text-align: center;
    }
}
.errImg {
    display: flex;
    align-items: center;
    justify-content: center;
}

.errImg img {
    width: 35%;
}

.errLink {
    margin-top: 2%;
    text-align: center;
}

.errLink a {
    padding: 0.7%;
    text-decoration: none;
    color: black;
    background-color: red;
    font-size: 2rem;
    font-family: Oswald, sans-serif;
    font-weight: bolder;
    border-radius: 20px;
    transition: color 0.2s ease-in-out;
}

.errLink a:hover {
    color: white;
}

@media only screen and (max-width:768px) {
    .errImg {
        margin-top: 10%;
    }
    .errImg img {
        width: 100%;
    }
    .errLink {
        margin: 12% 0;
    }
    .errLink a {
        padding: 2%;
        font-size: 1.8rem;
    }
}
