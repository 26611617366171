.cardCont {
    width: 420px;
    padding: 10%;
    margin-bottom: 15%;
    background-color: white;
    border-radius: 20px;
    text-align: center;
    flex-direction: column;
}

.swiper-slide {
    margin-top: 2%;
}

.cardCont:hover {
    transform: scale(1.03);
    transition: 0.2s ease-out;
    cursor: pointer;
}

.cardLogo {
    margin-bottom: 5%;
}

.cardDetails {
    font-family: 'Montserrat', sans-serif;
}

@media only screen and (max-width:768px) {
    .cardCont {
        width: 320px;
    }
    .cardDetails {
        font-size: 0.7rem;
    }
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}