* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.mainBody {
    padding-top: 4%;
}

@media only screen and (max-width:768px) {
    .mainBody {
        padding-top: 10%;
    }
}

::-webkit-scrollbar {
    width: 5px;
}

 ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

 ::-webkit-scrollbar-thumb {
    background: rgb(255, 0, 0);
    border-radius: 10px;
}

 ::-webkit-scrollbar-thumb:hover {
    background: #b30000;
}