.navContainer,
.navLogo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navContainer {
    width: 100%;
    position: fixed;
    top: 0;
    margin-bottom: 5%;
    background-color: white;
    border-radius: 0 0 20px 20px;
    box-shadow: 0 2px 10px 2px gray;
    z-index: 20;
}

.navLogo img {
    width: 45%;
}

.navTitles {
    text-decoration: none;
    color: rgb(0, 0, 0);
    width: 10%;
    font-family: 'Zen Kurenaido', sans-serif;
    font-size: 1.4rem;
    padding: 0.2%;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
}

.navTitles:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(255, 0, 0);
    box-shadow: 0 2px 10px 0px gray;
    text-shadow: 0 0.1rem #bcb4b4;
}

.activeNav {
    color: rgb(255, 255, 255);
    background-color: rgb(255, 0, 0);
    box-shadow: 0 2px 10px 0px gray;
    text-shadow: 0 0.1rem #bcb4b4;
}

.hamBurger {
    display: none;
}

@media only screen and (max-width: 768px) {
    .navLogo {
        justify-content: flex-start;
        margin-left: 10px;
    }
    .navLogo img {
        width: 35%;
        height: 0%;
        border-radius: 0 0 0 10px;
    }
    .navTitles {
        display: none;
    }
    .hamBurger {
        display: flex;
    }
}