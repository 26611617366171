.detailsCont {
    position: relative;
    padding: 2%;
    display: flex;
    flex-direction: column;
    font-family: 'Playfair Display', serif;
}

.detailsCont h1 {
    width: 24%;
    border-bottom: 1px solid red;
}

.detailsCont h3 {
    margin-top: 1%;
}

@media only screen and (max-width:768px) {
    .detailsCont {
        margin: 5%;
        font-size: 0.8rem;
    }
    .detailsCont h1 {
        width: 63%;
        border-bottom: 1px solid red;
    }
}