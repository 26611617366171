.abtCont {
    overflow: hidden;
}

.abtHeader h1 {
    margin-top: 30px;
    color: red;
    font-size: 3rem;
    font-family: 'Oswald', sans-serif;
    text-align: center;
}

.abt1,
.abt2,
.abt3,
.abt4 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: stretch;
}

.abtImg {
    width: 40%;
}

.abtImg img {
    width: 50%;
}

.abtContent {
    width: 50%;
    font-family: "Zen Kurenaido", sans-serif;
}

.abtContent h1 {
    font-size: 3rem;
}

.abtContent p {
    font-size: 1.4rem;
}

@media only screen and (max-width:768px) {
    .abt1,
    .abt2,
    .abt3,
    .abt4 {
        padding: 5%;
        flex-direction: column;
    }
    .abtImg img {
        width: 300px;
        height: 200px;
    }
    .abtContent {
        width: 100%;
    }
    .abtContent h1 {
        font-size: 1.9rem;
        text-align: center;
    }
    .abtContent p {
        font-size: 1rem;
        text-align: center;
    }
}