.homeDetails {
    margin-top: 3%;
    flex-direction: column;
    background-color: rgba(163, 163, 163, 0.6);
    border-radius: 30px;
}

.homeDetailsHeader {
    margin-top: 3%;
    font-family: 'Playfair Display', serif;
    font-style: italic;
}

.homeDetailsBody {
    flex-direction: row;
}

.technical,
.cost,
.support {
    width: 33.33%;
    margin: 3% 0 2% 0;
    flex-direction: column;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: large;
}

.technical,
.cost {
    border-right: 3px solid rgb(0, 0, 0);
}

.technicalLogo,
.costLogo,
.supportLogo {
    padding: 1.5%;
    border: 2px solid rgb(0, 0, 0);
    border-radius: 50%;
}

.technicalDetails,
.costDetails,
.supportDetails {
    color: red;
    font-weight: bolder;
}

.technicalDetails {
    margin: 3% 10% 2% 8%;
}

.costDetails {
    margin: 3% 5% 2% 5%;
}

.supportDetails {
    margin: 3% 2% 2% 4%;
}

@media only screen and (max-width:768px) {
    .homeDetailsHeader {
        margin-top: 5%;
        color: red;
    }
    .homeDetailsBody {
        flex-direction: column;
    }
    .technical,
    .cost,
    .support {
        width: auto;
    }
    .technical {
        margin-top: 10%;
    }
    .support {
        margin-bottom: 5%;
    }
    .technical,
    .cost {
        border-right: 0;
    }
    .technicalLogo,
    .costLogo,
    .supportLogo {
        border: 2px solid rgb(255, 0, 0);
    }
    .technicalDetails,
    .costDetails,
    .supportDetails {
        color: rgb(0, 0, 0);
        margin: 5% 5% 5% 5%;
    }
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}