.details {
    margin-top: 3%;
    padding: 2%;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 0px 5px gray;
    overflow: hidden;
}

.detailsImage {
    width: 30%;
    border-right: 1px solid gray;
}

.detailsImage img {
    width: 70%;
}

.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-button-next,
.swiper-button-prev {
    color: red !important;
}

.detailsDetail {
    width: 100%;
    margin-left: 2%;
}

.detailsSubHeader {
    position: relative;
    top: -10px;
    width: 14%;
    border-bottom: 1px solid red;
}

.detailsTable {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.detailsTable table {
    width: 100%;
    font-family: 'Baloo Bhaijaan 2', cursive;
    text-align: left;
}

.table1 {
    margin-right: 10%;
}

td,
th {
    padding: 0.5% 0 0.5% 0;
    /* border-bottom: 1px solid red; */
}

@media only screen and (max-width:768px) {
    .details {
        margin-top: 7%;
        flex-direction: column;
    }
    .detailsDetail {
        margin-top: 4%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .detailsImage {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid gray;
    }
    .detailsSubHeader {
        width: 34%;
        text-align: center;
    }
    .detailsTable {
        display: flex;
        flex-direction: column;
    }
    .detailsTable table {
        width: 100%;
        text-align: center;
    }
    td,
    th {
        padding: 3% 0 3% 0;
        border-bottom: none;
    }
}