.errImg {
    display: flex;
    align-items: center;
    justify-content: center;
}

.errImg img {
    width: 35%;
}

.errLink {
    margin-top: 2%;
    text-align: center;
}

.errLink a {
    padding: 0.7%;
    text-decoration: none;
    color: black;
    background-color: red;
    font-size: 2rem;
    font-family: Oswald, sans-serif;
    font-weight: bolder;
    border-radius: 20px;
    transition: color 0.2s ease-in-out;
}

.errLink a:hover {
    color: white;
}

@media only screen and (max-width:768px) {
    .errImg {
        margin-top: 10%;
    }
    .errImg img {
        width: 100%;
    }
    .errLink {
        margin: 12% 0;
    }
    .errLink a {
        padding: 2%;
        font-size: 1.8rem;
    }
}