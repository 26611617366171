.homeContainer {
    margin-bottom: 5%;
}

.homeSwiper img {
    width: 50%;
}

@media only screen and (max-width: 768px) {
    .homeContainer {
        margin-bottom: 10%;
    }
    .homeSwiper img {
        width: 100%;
    }
}