.footerContainer {
    width: 100%;
    margin-top: 3%;
    padding: 2% 2% 2% 5%;
    background-color: rgb(163, 163, 163);
    border-radius: 20px 20px 0 0;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
}

.footerContent {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid black;
}

.footerHeader {
    width: 50%;
}

.footerProducts a {
    text-decoration: none;
    color: black;
}

.footerHeader ul,
.footerProducts ul {
    margin-top: 1%;
    list-style: url("../../assets/pointer.svg");
    text-decoration: none;
}

.footerHeader ul li,
.footerProducts ul li {
    margin: 0.8rem;
}

.footerHeader ul li a,
.footerProducts ul li a {
    text-decoration: none;
    font-size: 1.5rem;
    color: black;
}

.footerHeader ul li a:hover,
.footerProducts a:hover,
.footerProducts ul li a:hover {
    text-decoration: underline red;
    transition: all 0.3s ease-in-out;
}

.footerFooter {
    width: 100%;
    margin-top: 3%;
    display: flex;
    flex-direction: row;
}

.footerCopyright {
    width: 100%;
}

.footerCopyright a {
    text-decoration: none;
    color: black;
}

.footerCopyright a:hover {
    text-decoration: underline red;
    transition: all 0.3s ease-in-out;
}

.footerSocial {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerSocial a {
    margin-left: 1%;
}

@media only screen and (max-width:600px) {
    .footerContainer {
        margin-top: 5%;
        padding: 5%;
    }
    .footerHeader {
        width: 100%;
        font-size: 0.8rem;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .footerHeader ul {
        list-style: none;
    }
    .footerHeader ul li a {
        font-size: 1.2rem;
    }
    .footerProducts {
        display: none;
    }
    .footerFooter {
        flex-direction: column-reverse;
    }
    .footerCopyright {
        text-align: center;
    }
}