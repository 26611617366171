.contactForm h3 {
    width: 26%;
    font-size: 1.5rem;
    text-align: center;
    border-bottom: 1px solid red;
}

.formBody {
    margin-top: 8%;
}

.formName,
.formNumber,
.formEmail,
.formSubject,
.formMessage {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /* margin-bottom: 5%; */
}

.formName input,
.formNumber input,
.formSubject input {
    color: red;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    font-size: 1.2rem;
    border: 1px solid red;
    border-radius: 8px;
    outline: none;
    text-align: center;
}

.formSubject input {
    width: 71%;
}

.formName input:focus,
.formNumber input:focus,
.formSubject input:focus,
.formEmail input:focus,
.formMessage textarea:focus {
    border: none;
    border-bottom: 2px solid rgb(0, 0, 0);
    border-radius: 0px;
}

.formEmail input {
    width: 73%;
    color: red;
    font-family: 'Oswald', sans-serif;
    font-size: 1.1rem;
    border: 1px solid red;
    border-radius: 8px;
    outline: none;
    text-align: center;
}

.formMessage textarea {
    font-size: 1rem;
    color: red;
    font-family: 'Oswald', sans-serif;
    border: none;
    border: 1px solid red;
    border-radius: 8px;
    outline: none;
    text-align: center;
}

.alert {
    margin-top: 1%;
    margin-bottom: 5%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: red;
    font-weight: bolder;
    font-size: 0.8rem;
}

.formName input::placeholder,
.formNumber input::placeholder,
.formEmail input::placeholder,
.formSubject input::placeholder,
.formMessage textarea::placeholder {
    text-transform: capitalize;
}

@media only screen and (max-width:768px) {
    .contactForm h3 {
        width: 35%;
    }
    .formName input,
    .formNumber input,
    .formEmail input,
    .formSubject input {
        font-size: 1rem;
    }
}

.formSubmit {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 2% 0 2% 0;
}

.formSubmit button {
    padding: 2.5%;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 172, 0);
    font-family: 'Oswald', sans-serif;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
    text-shadow: 0 0.1rem #000000;
}