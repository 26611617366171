.whoAreWe {
    margin-top: 5%;
    flex-direction: row;
    background-color: rgba(163, 163, 163, 0.6);
    border-radius: 30px;
    flex-direction: column;
    overflow: hidden;
}

.whoAreWeHeader {
    margin: 2% 0 1% 0;
    font-family: 'Playfair Display', serif;
    font-style: italic;
}

.whoAreWeBody {
    width: 100%;
    flex-direction: row;
    grid-gap: 40px;
}

.swiper-pagination-bullet-active {
    background: red !important;
}

@media only screen and (max-width:768px) {
    .whoAreWeBody {
        margin-top: 10%;
        grid-gap: 0px;
        flex-direction: column;
    }
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}