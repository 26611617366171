@import url('https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles&family=Montserrat:wght@300&family=Oswald:wght@300&display=swap');
.prodCont {
    margin: 1%;
    display: flex;
    flex-direction: column;
    position: relative;
    /* z-index: -10; */
}

@media only screen and (max-width:768px) {
    .prodCont {
        margin: 0% 5% 0% 5%;
        display: flex;
        flex-direction: column;
    }
}