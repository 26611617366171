.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactContainer {
    margin: 2% 5% 5% 5%;
    padding: 0.5% 1% 1% 1%;
    font-family: 'Oswald', sans-serif;
    font-size: 1.2rem;
    box-shadow: 0px 0px 10px 2px gray;
    border-radius: 20px;
    align-items: flex-start;
}

.contactHeader h3 {
    font-size: 2rem;
    text-align: center;
    border-bottom: 1px solid red;
}

.contactBody {
    font-size: 1.3rem;
    margin-top: 2%;
    flex-direction: row;
}

.contactAddress,
.contactForm {
    width: 100%;
    padding: 2%;
    border-radius: 20px;
    border: 1px solid red;
    flex-direction: column;
}

.contactAddress {
    padding: 4.7%;
}

.contactAddress:hover,
.contactForm:hover {
    box-shadow: 0px 0px 5px rgb(255, 0, 0);
}

.perMail img {
    margin-right: 2%;
    width: 6.5%;
}

.perAddress {
    width: 100%;
}

.perName,
.perNumber,
.perMail {
    width: 100%;
    margin-bottom: 5%;
}

.perName svg {
    margin-right: 2%;
}

.perNumber svg {
    margin-right: 1%;
}

.perAddress svg {
    margin-right: 1%;
    margin-bottom: 21.5%;
}

.perNumber a,
.perMail a,
.perAddress a {
    color: rgb(0, 0, 0);
    text-decoration: none;
}

.perNumber a:hover,
.perMail a:hover,
.perAddress a:hover {
    color: rgb(255, 0, 0);
    text-decoration: none;
}

.contactAddress,
.contactForm {
    margin: 5%;
}

@media only screen and (max-width:768px) {
    .contactContainer {
        margin-top: 7%;
    }
    .contactHeader h3 {
        font-size: 1.8rem;
    }
    .contactAddress,
    .contactForm {
        border-radius: 0px;
        border-top: 1px solid red;
        border-left: 0px;
        border-bottom: 1px solid red;
        border-right: 0px;
    }
    .contactAddress:hover,
    .contactForm:hover {
        box-shadow: 0px 0px 5px rgb(255, 255, 255);
    }
    .perMail img {
        margin-right: 2%;
        width: 8.5%;
    }
    .contactBody {
        font-size: 1rem;
        margin-top: 6%;
        flex-direction: column;
    }
}