.hamContainer,
#hamLabel {
    width: 100%;
    height: 100%;
    flex-direction: column;
}

input[type=checkbox] {
    display: none;
}

#hamLabel span:nth-child(1),
#hamLabel span:nth-child(2),
#hamLabel span:nth-child(3) {
    width: 70%;
    height: .2rem;
    background-color: black;
    margin: 2px 40px 2px 2px;
    border-radius: 20px;
    transition: 0.3s transform ease-in-out;
}

.hamOption {
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    position: absolute;
    color: white;
    background-color: rgba(0, 0, 0, 0.61);
    display: none;
    opacity: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.hamOption ul {
    list-style: none;
    flex-direction: column;
}

.hamOption li {
    margin: 10%;
}

#ham:checked~.hamOption {
    display: flex;
    opacity: 1;
    animation: fade 1s;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.navBtn {
    background-color: #eee;
    border: none;
    padding: 1rem;
    font-family: 'Quicksand', sans-serif;
    font-size: 1rem;
    width: 10em;
    border-radius: 1rem;
    color: rgb(255, 0, 0);
    box-shadow: 0 0.4rem rgb(165, 165, 165);
    cursor: pointer;
}

.navBtn:active {
    color: white;
    box-shadow: 0 0.2rem #dfd9d9;
    transform: translateY(0.2rem);
}

.navBtn:hover:not(:disabled) {
    background: rgb(255, 0, 0);
    color: white;
    text-shadow: 0 0.1rem #bcb4b4;
}

#ham:checked~#hamLabel span:nth-child(1) {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

#ham:checked~#hamLabel span:nth-child(2),
#ham:checked~#hamLabel span:nth-child(3) {
    width: 8%;
    position: absolute;
    background-color: rgb(255, 0, 0);
    z-index: 1;
}

#ham:checked~#hamLabel span:nth-child(2) {
    transform: rotateZ(45deg);
}

#ham:checked~#hamLabel span:nth-child(3) {
    transform: rotateZ(-45deg);
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}