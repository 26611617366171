.prodBody {
    margin-top: 3%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.prodCat1,
.prodCat2 {
    width: 100%;
    height: 40vh;
    overflow: hidden;
    padding: 1%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.catImage1,
.catImage2 {
    position: absolute;
    bottom: 35%;
    left: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.catImage1 img,
.catImage2 img {
    width: 85%;
    border-radius: 50%;
    box-shadow: 0px 0px 8px 3px rgba(255, 0, 0, 0.603);
    background-color: white;
}

.catDetail {
    width: 100%;
    height: 160px;
    padding: 1%;
    background-color: rgba(163, 163, 163, 0.5);
    border-radius: 20px;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
}

.text {
    margin-left: 40%;
}

.catHead {
    font-family: 'Oswald', sans-serif;
}

.catBody {
    font-size: 1.5rem;
    font-family: "Zen Kurenaido", sans-serif;
}

.catLink {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.catLink a {
    font-size: 1.1rem;
    color: black;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    text-align: center;
    display: flex;
    align-items: center;
}

.catLink a:hover {
    color: red;
    text-decoration: underline black;
}

@media only screen and (max-width:768px) {
    .prodBody {
        margin-top: 0%;
        flex-direction: column;
        overflow: hidden;
    }
    .prodCat1,
    .prodCat2 {
        width: auto;
        height: 100%;
        margin: 7% 0 0 0;
        padding: 3%;
        border-radius: 20px;
        background-color: rgba(163, 163, 163, 0.5);
    }
    .catImage1,
    .catImage2 {
        position: relative;
        bottom: 0;
        left: 0;
        display: flex;
    }
    .catImage1 img,
    .catImage2 img {
        width: 150px;
        border-radius: 0%;
        box-shadow: none;
        background-color: transparent;
    }
    .catDetail {
        width: 100%;
        padding: 2%;
        height: 200px;
        background-color: transparent;
    }
    .catHead {
        font-size: .8rem;
    }
    .catBody {
        font-size: 1.3rem;
    }
    .text {
        margin-left: 2%;
    }
}